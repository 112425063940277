
import OrganicSignupModal from '~/components/Molecules/Organic/SignupModal/SignupModal.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    OrganicSignupModal,
  },

  computed: {
    ...mapGetters('modalSignup', ['isSignupModalOpen']),
  },
}
