
import signupCarousel from '~/components/Molecules/OrganicSquad/SignupCarousel/SignupCarousel.vue'
import { mapActions } from 'vuex'
import device from '~/mixins/device.js'

export default {
  mixins: [device],
  components: {
    signupIframe: () => import('~/components/Molecules/OrganicSquad/SignupIframe/SignupIframe.vue'),
    signupCarousel,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    embed: Object,
    content: Object,
    gtm: Object,
  },

  data() {
    return {
      dataLayer: {},
      device: null,
      showFooter: true,
    }
  },

  methods: {
    ...mapActions('modalSignup', ['setSignupModalOpen']),

    closeModal() {
      document.body.style.overflow = 'initial'
      this.setSignupModalOpen(false)
    },
  },

  computed: {
    locale() {
      return this.$i18n.locale
    },

    route() {
      return this.$route
    },

    images() {
      return this.content.images.length > 0 ? this.content.images : []
    },
  },
}
